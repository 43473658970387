import { observer } from 'mobx-react'
import { FC, useContext, useState } from 'react'
import { StoreContext } from '@components/App'
import {
  AboutContainer,
  CheckboxRow,
  HighlightedText,
  SectionTitle,
  SettingsButton,
  SettingsCategory,
  SettingsComponent,
  SettingsTitle,
} from './Settings.styles'
import { useTheme } from 'styled-components'
import { Select, Spacer } from '@styles/generic.styles'
import { SelectItem } from '@customTypes/General'
import { Checkbox } from '@components/Checkbox'
import { ThemeImporter } from './Components/ThemeImporter'
import { ThemeSelector } from './Components/ThemeSelector'

export type Stage = 'prod' | 'local' | 'dev' | 'dev-02' | 'dev-03' | 'dev-04' | 'dev-05' | 'dev-06' | 'dev-07'

const stages = ['prod', 'local', 'dev', 'dev-02', 'dev-03', 'dev-04', 'dev-05', 'dev-06', 'dev-07']

export const Settings: FC = observer(() => {
  const store = useContext(StoreContext)
  const { selectedStage } = store.AppState
  const { rightSidebar } = store.UserInterfaceState
  const theme = useTheme()

  const [selectedStageValue, setSelectedStageValue] = useState<SelectItem | null>({
    label: selectedStage,
    value: selectedStage,
  })

  const setSelectedStage = (stage: Stage) => {
    store.AppState.setStage(stage)
  }

  return (
    <SettingsComponent>
      <SettingsTitle>Settings</SettingsTitle>

      <SettingsCategory>
        <SectionTitle>Preferences</SectionTitle>

        <CheckboxRow>
          <Checkbox
            value={theme.animations.enabled}
            onChange={() => {
              store.ThemeState.toggleAnimations()
            }}
          />
          Animations enabled.
        </CheckboxRow>

        <CheckboxRow>
          <Checkbox
            value={rightSidebar.shouldHideOnEmpty}
            onChange={() => {
              store.UserInterfaceState.setRightSidebarShouldHideOnEmpty(!rightSidebar.shouldHideOnEmpty)
            }}
          />
          Hide right sidebar when empty.
        </CheckboxRow>
      </SettingsCategory>

      <ThemeSelector />

      <ThemeImporter />

      <SettingsCategory>
        Wipe local storage (helpful if you encounter any issues or new major version is released)
        <SettingsButton
          onClick={() => {
            localStorage.clear()
            window.location.reload()
          }}
        >
          Wipe
        </SettingsButton>
      </SettingsCategory>

      <AboutContainer>
        <SectionTitle>PinkHub App</SectionTitle>

        <div>
          Version: <HighlightedText>Beta 1.0.0</HighlightedText>
        </div>
        <div>
          By: <HighlightedText>Pink Angry Snakes</HighlightedText>
        </div>
      </AboutContainer>

      {/* <SettingsCategory>
        <label>Selected environment: (WiP)</label>
        <Select
          options={stages.map((stage) => ({ label: stage, value: stage }))}
          onChange={(val: SelectItem) => setSelectedStageValue(val)}
          value={selectedStageValue}
        />
        <SettingsButton
          onClick={() => {
            if (!selectedStageValue?.value) return
            setSelectedStage(selectedStageValue.value as Stage)
            window.location.reload()
          }}
        >
          Apply
        </SettingsButton>
      </SettingsCategory> */}
    </SettingsComponent>
  )
})
