import { FC } from 'react'
import { HeaderWrapper } from './Header.styles'
import { PinkhubMesh } from '@components/Brandings/PinkhubMesh'

export const Header: FC = () => {
  return (
    <HeaderWrapper>
      <PinkhubMesh />
    </HeaderWrapper>
  )
}
