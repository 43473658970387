import { styled } from 'styled-components'
import { Animatable } from '@styles/generic.styles'

export const RightBarWrapper = styled.section`
  grid-area: rightsidebar;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors['sideBar.background']};
  color: ${({ theme }) => theme.colors['sideBar.foreground']};
  position: relative;
  padding: 10px;

  ${Animatable}
`

export const CategoryWrapper = styled.div`
  padding: 20px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const CategoryTitle = styled.h2`
  width: 100%;
  color: ${({ theme }) => theme.colors['menu.selectionForeground']};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
`

export const ReferenceList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
`

export const ReferenceItem = styled.li`
  padding: 0px 14px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors['editor.background']};
  color: ${({ theme }) => theme.colors['sideBar.foreground']};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors['editor.hoverHighlightBackground']};
  }

  ${Animatable}
`

export const VersionList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const VersionItem = styled.li`
  cursor: pointer;
  padding: 10px 20px;
  margin: 0 -20px;

  &:hover {
    background-color: ${({ theme }) => theme.colors['editor.hoverHighlightBackground']};
  }

  ${Animatable}
`

export const Button = styled.button`
  outline: none;
  cursor: pointer;
  padding: 0 50px;
  height: 40px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors['dropdown.foreground']};
  border: 1px solid ${({ theme }) => theme.colors['dropdown.foreground']};
  color: ${({ theme }) => theme.colors['dropdown.background']};

  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 39px;
`

export const ActionsList = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  flex-direction: column;
`
