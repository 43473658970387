import { RootStore } from '@store/Root.store'
import { macos } from 'platform-detect'

export const parseToKeyboardKeyDisplay = (key: string) => {
  switch (key) {
    case 'Escape':
      return 'Esc'
    case 'ArrowUp':
      return '↑'
    case 'ArrowDown':
      return '↓'
    case 'ArrowLeft':
      return '←'
    case 'ArrowRight':
      return '→'
    case 'Command':
      return '⌘'

    default:
      return key
  }
}

const parseMacOs = (shortcuts) => {
  if (macos) {
    // more replacements will be added here when more shortcuts are added
    return shortcuts.replaceAll('ctrl', 'command')
  }

  return shortcuts
}

interface HandleShortcutsArgs {
  keyName: string
  e: KeyboardEvent
  store: RootStore
}

export const shortcutDescriptions = {
  'ctrl+s': 'Toggle split view',
  'ctrl+b': 'Toggle left sidebar',
  'command+s': 'Toggle split view',
  'command+b': 'Toggle left sidebar',
  Escape: 'Close modal',
}

export const SHORTCUTS = parseMacOs('ctrl+s,ctrl+b,Escape')

export const handleShortcuts = ({ keyName, e, store }: HandleShortcutsArgs) => {
  switch (keyName) {
    case 'ctrl+s':
      e.preventDefault()
      store.CodeViewState.toggleHorizontalSplit()
      break
    case 'command+s':
      e.preventDefault()
      store.CodeViewState.toggleHorizontalSplit()
      break
    case 'ctrl+b':
      e.preventDefault()
      store.UserInterfaceState.toggleLeftSidebar()
      break
    case 'command+b':
      e.preventDefault()
      store.UserInterfaceState.toggleLeftSidebar()
      break
    case 'Escape':
      e.preventDefault()
      store.ModalState.closeAllModals()
      break
    default:
      break
  }
}
