import { action, makeAutoObservable } from 'mobx'
import { STAGE } from '../constants/envs'
import { Stage } from '@modules/Settings/Settings'

const DEV_MODE = process.env.DEV_MODE === 'true'

export class AppStateStore {
  DEV_MODE = DEV_MODE || false
  rootStore
  selectedStage: Stage = STAGE as Stage

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore
    this.initStage()
  }

  @action.bound setStage(stage: Stage) {
    this.selectedStage = stage
    localStorage.setItem('stage', stage)
  }

  @action.bound initStage() {
    const storedStage = localStorage.getItem('stage')
    if (storedStage) {
      this.selectedStage = storedStage as Stage
    } else {
      this.selectedStage = STAGE as Stage
    }
  }
}
