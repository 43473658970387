import { toast } from 'react-toastify'
import { axiosInstance } from '@methods/axiosConfig'
import { STAGE } from '../constants/envs'
import { Client, ClientListItem } from '@customTypes/Client'
import { Entity, EntityListItem } from '@customTypes/Entity'
import axios, { AxiosError } from 'axios'

export interface ApiResponse<T> {
  success: boolean
  data?: T
  error?: AxiosError
}

export const getClientList = async (id?: string) => {
  try {
    return await axiosInstance.get<ClientListItem[]>(`/configurations`, {
      params: {
        type: 'Client',
        clientId: id || '',
      },
    })
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const getEntityList = async (id?: string) => {
  try {
    return await axiosInstance.get<EntityListItem[]>(`/configurations`, {
      params: {
        type: 'Entity',
        clientId: id || '',
      },
    })
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const getClient = async (clientId: string): Promise<ApiResponse<Client>> => {
  let res
  try {
    if (STAGE === 'local') {
      res = await axiosInstance.get<Client>(`/clientMock`)
    } else {
      res = await axiosInstance.get<Client>(`/configuration`, {
        params: {
          type: 'Client',
          clientId,
        },
      })
    }

    return { success: true, data: res.data }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return { success: false, error: error }
    } else {
      console.error('Unexpected error', error)
      return { success: false }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putConfiguration = async (configuration: any): Promise<ApiResponse<null>> => {
  if (STAGE === 'local') {
    return { success: true, data: null }
  }

  try {
    await axiosInstance.put(`/configuration`, configuration)

    return { success: true, data: null }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return { success: false, error: error }
    } else {
      console.error('Unexpected error', error)
      return { success: false }
    }
  }
}

export const getEntity = async (clientId: string): Promise<ApiResponse<Entity>> => {
  try {
    let res
    if (STAGE === 'local') {
      res = await axiosInstance.get<Entity>(`/entityMock`)
    } else {
      res = await axiosInstance.get<Entity>(`/configuration`, {
        params: {
          type: 'Entity',
          clientId,
        },
      })
    }

    return { success: true, data: res.data }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return { success: false, error: error }
    } else {
      console.error('Unexpected error', error)
      return { success: false }
    }
  }
}

export const createConfiguration = async (
  configuration: Client,
  allowedGroups: string[],
): Promise<ApiResponse<Client>> => {
  try {
    const res = await axiosInstance.post<Client>(`/configuration`, {
      configuration,
      allowedGroups,
    })

    return { success: true, data: res.data }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return { success: false, error: error }
    } else {
      console.error('Unexpected error', error)
      return { success: false }
    }
  }
}

export const deleteConfiguration = async (clientId: string, type: 'Client' | 'Entity'): Promise<ApiResponse<null>> => {
  try {
    return await axiosInstance.delete(`/configuration/${clientId}/${type}`)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return { success: false, error: error }
    } else {
      console.error('Unexpected error', error)
      return { success: false }
    }
  }
}
