import styled from 'styled-components'

export const SettingsTitle = styled.h1`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors['editor.foreground']};
`

export const SettingsComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
  height: 100%;
`

export const SettingsCategory = styled.div`
  /* min-width: 500px; */
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors['activityBar.border']};
  margin-bottom: 20px;
`

export const SettingsButton = styled.button`
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.colors['button.background']};
  height: 40px;
  padding: 0 20px;
  color: ${({ theme }) => theme.colors['button.foreground']};
  cursor: pointer;
  border-radius: 2px;

  &:hover {
    filter: brightness(1.1);
  }

  &:disabled {
    filter: grayscale();
  }
`

export const SectionTitle = styled.h2`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const HighlightedText = styled.span`
  color: ${({ theme }) => theme.colors['badge.background']};
`

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin: 20px 0 8px 0;
`

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  position: fixed;
  bottom: 20px;
  left: 100px;
`

export const CheckboxRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
