import { FC } from 'react'
import { useTheme } from 'styled-components'
export const PinkhubLogo: FC = () => {
  const theme = useTheme()
  const textColor = theme?.name === 'BeardedTheme Milkshake-raspberry' ? '#171717' : '#fff'

  return (
    <svg width="146" height="74" viewBox="0 0 146 74" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5" filter="url(#filter0_f_1_255)">
        <path
          d="M4 10.13C4 6.74 6.74 4 10.13 4H63.87C67.25 4 70 6.74 70 10.13V63.87C70 67.25 67.26 70 63.87 70H10.13C6.75 70 4 67.26 4 63.87V10.13Z"
          fill="#CCA2B1"
        />
        <path
          d="M8.09 8.71C6.71 8.71 5.37 8.88 4.08 9.17C4.03 9.48 4 9.8 4 10.13V44.68C5.32 44.98 6.68 45.16 8.09 45.16C18.15 45.16 26.31 37 26.31 26.94C26.31 16.88 18.16 8.71 8.09 8.71Z"
          fill="#91477C"
        />
        <path
          d="M19.57 24.74C13.86 24.74 8.53999 26.35 4.00999 29.14V63.87C4.00999 67.25 6.74999 70 10.14 70H44.87C47.66 65.48 49.27 60.15 49.27 54.44C49.27 38.04 35.97 24.74 19.57 24.74Z"
          fill="#FF2768"
        />
        <path
          d="M63.87 4H52.46C48.77 7.33 46.44 12.14 46.44 17.51C46.44 27.57 54.6 35.73 64.66 35.73C66.52 35.73 68.31 35.45 69.99 34.93V10.13C69.99 6.75 67.25 4 63.86 4H63.87Z"
          fill="#91477C"
        />
        <path
          d="M67.86 56.66C67.86 62.83 62.86 67.84 56.68 67.84C50.5 67.84 45.5 62.84 45.5 56.66C45.5 50.48 50.5 45.48 56.68 45.48C62.86 45.48 67.86 50.48 67.86 56.66Z"
          fill="#91477C"
        />
        <path
          d="M42.24 15.57C42.24 19.36 39.17 22.43 35.38 22.43C31.59 22.43 28.52 19.36 28.52 15.57C28.52 11.78 31.59 8.71 35.38 8.71C39.17 8.71 42.24 11.78 42.24 15.57Z"
          fill="#721743"
        />
        <path
          d="M68.64 19.34C68.64 23.13 65.57 26.2 61.78 26.2C57.99 26.2 54.92 23.13 54.92 19.34C54.92 15.55 57.99 12.48 61.78 12.48C65.57 12.48 68.64 15.55 68.64 19.34Z"
          fill="#721743"
        />
        <path
          d="M26.57 11.98C26.57 14.3 24.69 16.19 22.36 16.19C20.03 16.19 18.15 14.31 18.15 11.98C18.15 9.65 20.03 7.77 22.36 7.77C24.69 7.77 26.57 9.65 26.57 11.98Z"
          fill="#721743"
        />
        <path
          d="M69 41.21C69 43.53 67.12 45.42 64.79 45.42C62.46 45.42 60.58 43.54 60.58 41.21C60.58 38.88 62.46 37 64.79 37C67.12 37 69 38.88 69 41.21Z"
          fill="#FF2768"
        />
        <path
          d="M51.09 62.89C51.09 65.21 49.21 67.1 46.88 67.1C44.55 67.1 42.67 65.22 42.67 62.89C42.67 60.56 44.55 58.68 46.88 58.68C49.21 58.68 51.09 60.56 51.09 62.89Z"
          fill="#721743"
        />
        <path
          d="M30.35 35.55C30.35 37.87 28.47 39.76 26.14 39.76C23.81 39.76 21.93 37.88 21.93 35.55C21.93 33.22 23.81 31.34 26.14 31.34C28.47 31.34 30.35 33.22 30.35 35.55Z"
          fill="#EDAEAE"
        />
        <path
          d="M36.59 60.83C36.59 64.62 33.52 67.69 29.73 67.69C25.94 67.69 22.87 64.62 22.87 60.83C22.87 57.04 25.94 53.97 29.73 53.97C33.52 53.97 36.59 57.04 36.59 60.83Z"
          fill="#721743"
        />
        <path
          d="M51.83 24.61C51.83 30.78 46.83 35.79 40.65 35.79C34.47 35.79 29.47 30.79 29.47 24.61C29.47 18.43 34.47 13.43 40.65 13.43C46.83 13.43 51.83 18.43 51.83 24.61Z"
          fill="#FF2768"
        />
        <path
          d="M63.93 44.8C63.93 48.59 60.86 51.66 57.07 51.66C53.28 51.66 50.21 48.59 50.21 44.8C50.21 41.01 53.28 37.94 57.07 37.94C60.86 37.94 63.93 41.01 63.93 44.8Z"
          fill="#EDAEAE"
        />
        <path
          d="M21.86 54.41C21.86 56.73 19.98 58.62 17.65 58.62C15.32 58.62 13.44 56.74 13.44 54.41C13.44 52.08 15.32 50.2 17.65 50.2C19.98 50.2 21.86 52.08 21.86 54.41Z"
          fill="#721743"
        />
        <path
          d="M25.1 48.96C25.1 49.84 24.39 50.54 23.52 50.54C22.65 50.54 21.94 49.83 21.94 48.96C21.94 48.09 22.65 47.38 23.52 47.38C24.39 47.38 25.1 48.09 25.1 48.96Z"
          fill="#721743"
        />
        <path
          d="M5.22 23.8C4.8 23.8 4.4 23.84 4 23.91V37.4C4.4 37.47 4.8 37.51 5.22 37.51C9.01 37.51 12.08 34.44 12.08 30.65C12.08 26.86 9.01 23.79 5.22 23.79V23.8Z"
          fill="#721743"
        />
        <path
          d="M42.25 46.69C42.25 50.48 39.18 53.55 35.39 53.55C31.6 53.55 28.53 50.48 28.53 46.69C28.53 42.9 31.6 39.83 35.39 39.83C39.18 39.83 42.25 42.9 42.25 46.69Z"
          fill="#FBD2D2"
        />
        <path
          d="M47.84 39.58C47.84 41.01 46.68 42.16 45.26 42.16C43.84 42.16 42.68 41 42.68 39.58C42.68 38.16 43.84 37 45.26 37C46.68 37 47.84 38.16 47.84 39.58Z"
          fill="#FBD2D2"
        />
        <path
          d="M50.56 32.93C50.56 33.81 49.85 34.51 48.98 34.51C48.11 34.51 47.4 33.8 47.4 32.93C47.4 32.06 48.11 31.35 48.98 31.35C49.85 31.35 50.56 32.06 50.56 32.93Z"
          fill="#EDAEAE"
        />
        <path
          d="M46.37 20.47C46.37 22.79 44.49 24.68 42.16 24.68C39.83 24.68 37.95 22.8 37.95 20.47C37.95 18.14 39.83 16.26 42.16 16.26C44.49 16.26 46.37 18.14 46.37 20.47Z"
          fill="#FFCD1E"
        />
        <path
          d="M62.4 13.87C62.4 16.19 60.52 18.08 58.19 18.08C55.86 18.08 53.98 16.2 53.98 13.87C53.98 11.54 55.86 9.66 58.19 9.66C60.52 9.66 62.4 11.54 62.4 13.87Z"
          fill="#FBD2D2"
        />
        <path
          d="M60.52 60.07C60.52 62.39 58.64 64.28 56.31 64.28C53.98 64.28 52.1 62.4 52.1 60.07C52.1 57.74 53.98 55.86 56.31 55.86C58.64 55.86 60.52 57.74 60.52 60.07Z"
          fill="#FFCD1E"
        />
        <path
          d="M21.51 22.18C21.51 25.97 18.44 29.04 14.65 29.04C10.86 29.04 7.79 25.97 7.79 22.18C7.79 18.39 10.86 15.32 14.65 15.32C18.44 15.32 21.51 18.39 21.51 22.18Z"
          fill="#FFCD1E"
        />
        <path
          d="M63.87 4H52.29C53.51 12.76 60.94 19.52 69.99 19.68V10.13C69.99 6.75 67.25 4 63.86 4H63.87Z"
          fill="#FF2768"
        />
        <path d="M17.25 4H10.13C6.74 4 4 6.74 4 10.13V12.91C9.67 12.13 14.5 8.75 17.25 4Z" fill="#FF2768" />
        <path
          d="M9.54 32.29C7.52 32.29 5.63 32.83 4 33.77V53.18C5.63 54.11 7.52 54.66 9.54 54.66C15.71 54.66 20.72 49.65 20.72 43.48C20.72 37.31 15.71 32.3 9.54 32.3V32.29Z"
          fill="#FBD2D2"
        />
        <path
          d="M62.47 56.11C62.47 59.9 65.54 62.97 69.33 62.97C69.56 62.97 69.78 62.96 70 62.94V49.29C69.78 49.27 69.56 49.26 69.33 49.26C65.54 49.26 62.47 52.33 62.47 56.12V56.11Z"
          fill="#FBD2D2"
        />
      </g>
      <g opacity="0.86">
        <path
          d="M8.09 8.71C6.71 8.71 5.37 8.88 4.08 9.17C4.03 9.48 4 9.8 4 10.13V44.68C5.32 44.98 6.68 45.16 8.09 45.16C18.15 45.16 26.31 37 26.31 26.94C26.31 16.88 18.16 8.71 8.09 8.71Z"
          fill="#91477C"
        />
        <path
          d="M19.57 24.74C13.86 24.74 8.53999 26.35 4.00999 29.14V63.87C4.00999 67.25 6.74999 70 10.14 70H44.87C47.66 65.48 49.27 60.15 49.27 54.44C49.27 38.04 35.97 24.74 19.57 24.74Z"
          fill="#FF2768"
        />
        <path
          d="M63.87 4H52.46C48.77 7.33 46.44 12.14 46.44 17.51C46.44 27.57 54.6 35.73 64.66 35.73C66.52 35.73 68.31 35.45 69.99 34.93V10.13C69.99 6.75 67.25 4 63.86 4H63.87Z"
          fill="#91477C"
        />
        <path
          d="M67.86 56.66C67.86 62.83 62.86 67.84 56.68 67.84C50.5 67.84 45.5 62.84 45.5 56.66C45.5 50.48 50.5 45.48 56.68 45.48C62.86 45.48 67.86 50.48 67.86 56.66Z"
          fill="#91477C"
        />
        <path
          d="M42.24 15.57C42.24 19.36 39.17 22.43 35.38 22.43C31.59 22.43 28.52 19.36 28.52 15.57C28.52 11.78 31.59 8.71 35.38 8.71C39.17 8.71 42.24 11.78 42.24 15.57Z"
          fill="#721743"
        />
        <path
          d="M68.64 19.34C68.64 23.13 65.57 26.2 61.78 26.2C57.99 26.2 54.92 23.13 54.92 19.34C54.92 15.55 57.99 12.48 61.78 12.48C65.57 12.48 68.64 15.55 68.64 19.34Z"
          fill="#721743"
        />
        <path
          d="M26.57 11.98C26.57 14.3 24.69 16.19 22.36 16.19C20.03 16.19 18.15 14.31 18.15 11.98C18.15 9.65 20.03 7.77 22.36 7.77C24.69 7.77 26.57 9.65 26.57 11.98Z"
          fill="#721743"
        />
        <path
          d="M69 41.21C69 43.53 67.12 45.42 64.79 45.42C62.46 45.42 60.58 43.54 60.58 41.21C60.58 38.88 62.46 37 64.79 37C67.12 37 69 38.88 69 41.21Z"
          fill="#FF2768"
        />
        <path
          d="M51.09 62.89C51.09 65.21 49.21 67.1 46.88 67.1C44.55 67.1 42.67 65.22 42.67 62.89C42.67 60.56 44.55 58.68 46.88 58.68C49.21 58.68 51.09 60.56 51.09 62.89Z"
          fill="#721743"
        />
        <path
          d="M30.35 35.55C30.35 37.87 28.47 39.76 26.14 39.76C23.81 39.76 21.93 37.88 21.93 35.55C21.93 33.22 23.81 31.34 26.14 31.34C28.47 31.34 30.35 33.22 30.35 35.55Z"
          fill="#EDAEAE"
        />
        <path
          d="M36.59 60.83C36.59 64.62 33.52 67.69 29.73 67.69C25.94 67.69 22.87 64.62 22.87 60.83C22.87 57.04 25.94 53.97 29.73 53.97C33.52 53.97 36.59 57.04 36.59 60.83Z"
          fill="#721743"
        />
        <path
          d="M51.83 24.61C51.83 30.78 46.83 35.79 40.65 35.79C34.47 35.79 29.47 30.79 29.47 24.61C29.47 18.43 34.47 13.43 40.65 13.43C46.83 13.43 51.83 18.43 51.83 24.61Z"
          fill="#FF2768"
        />
        <path
          d="M63.93 44.8C63.93 48.59 60.86 51.66 57.07 51.66C53.28 51.66 50.21 48.59 50.21 44.8C50.21 41.01 53.28 37.94 57.07 37.94C60.86 37.94 63.93 41.01 63.93 44.8Z"
          fill="#EDAEAE"
        />
        <path
          d="M21.86 54.41C21.86 56.73 19.98 58.62 17.65 58.62C15.32 58.62 13.44 56.74 13.44 54.41C13.44 52.08 15.32 50.2 17.65 50.2C19.98 50.2 21.86 52.08 21.86 54.41Z"
          fill="#721743"
        />
        <path
          d="M25.1 48.96C25.1 49.84 24.39 50.54 23.52 50.54C22.65 50.54 21.94 49.83 21.94 48.96C21.94 48.09 22.65 47.38 23.52 47.38C24.39 47.38 25.1 48.09 25.1 48.96Z"
          fill="#721743"
        />
        <path
          d="M5.22 23.8C4.8 23.8 4.4 23.84 4 23.91V37.4C4.4 37.47 4.8 37.51 5.22 37.51C9.01 37.51 12.08 34.44 12.08 30.65C12.08 26.86 9.01 23.79 5.22 23.79V23.8Z"
          fill="#721743"
        />
        <path
          d="M42.25 46.69C42.25 50.48 39.18 53.55 35.39 53.55C31.6 53.55 28.53 50.48 28.53 46.69C28.53 42.9 31.6 39.83 35.39 39.83C39.18 39.83 42.25 42.9 42.25 46.69Z"
          fill="#FBD2D2"
        />
        <path
          d="M47.84 39.58C47.84 41.01 46.68 42.16 45.26 42.16C43.84 42.16 42.68 41 42.68 39.58C42.68 38.16 43.84 37 45.26 37C46.68 37 47.84 38.16 47.84 39.58Z"
          fill="#FBD2D2"
        />
        <path
          d="M50.56 32.93C50.56 33.81 49.85 34.51 48.98 34.51C48.11 34.51 47.4 33.8 47.4 32.93C47.4 32.06 48.11 31.35 48.98 31.35C49.85 31.35 50.56 32.06 50.56 32.93Z"
          fill="#EDAEAE"
        />
        <path
          d="M46.37 20.47C46.37 22.79 44.49 24.68 42.16 24.68C39.83 24.68 37.95 22.8 37.95 20.47C37.95 18.14 39.83 16.26 42.16 16.26C44.49 16.26 46.37 18.14 46.37 20.47Z"
          fill="#FFCD1E"
        />
        <path
          d="M62.4 13.87C62.4 16.19 60.52 18.08 58.19 18.08C55.86 18.08 53.98 16.2 53.98 13.87C53.98 11.54 55.86 9.66 58.19 9.66C60.52 9.66 62.4 11.54 62.4 13.87Z"
          fill="#FBD2D2"
        />
        <path
          d="M60.52 60.07C60.52 62.39 58.64 64.28 56.31 64.28C53.98 64.28 52.1 62.4 52.1 60.07C52.1 57.74 53.98 55.86 56.31 55.86C58.64 55.86 60.52 57.74 60.52 60.07Z"
          fill="#FFCD1E"
        />
        <path
          d="M21.51 22.18C21.51 25.97 18.44 29.04 14.65 29.04C10.86 29.04 7.79 25.97 7.79 22.18C7.79 18.39 10.86 15.32 14.65 15.32C18.44 15.32 21.51 18.39 21.51 22.18Z"
          fill="#FFCD1E"
        />
        <path
          d="M63.87 4H52.29C53.51 12.76 60.94 19.52 69.99 19.68V10.13C69.99 6.75 67.25 4 63.86 4H63.87Z"
          fill="#FF2768"
        />
        <path d="M17.25 4H10.13C6.74 4 4 6.74 4 10.13V12.91C9.67 12.13 14.5 8.75 17.25 4Z" fill="#FF2768" />
        <path
          d="M9.54 32.29C7.52 32.29 5.63 32.83 4 33.77V53.18C5.63 54.11 7.52 54.66 9.54 54.66C15.71 54.66 20.72 49.65 20.72 43.48C20.72 37.31 15.71 32.3 9.54 32.3V32.29Z"
          fill="#FBD2D2"
        />
        <path
          d="M62.47 56.11C62.47 59.9 65.54 62.97 69.33 62.97C69.56 62.97 69.78 62.96 70 62.94V49.29C69.78 49.27 69.56 49.26 69.33 49.26C65.54 49.26 62.47 52.33 62.47 56.12V56.11Z"
          fill="#FBD2D2"
        />
      </g>
      <path
        d="M129.36 11H133.798V22.9683L139.391 17.156H144.641L138.547 23.0933L145.328 33.3116H139.954L135.516 26.0932L133.798 27.7494V33.3116H129.36V11Z"
        fill={textColor}
      />
      <path
        d="M110.431 17.1561H114.649V19.406H114.743C115.305 18.4685 116.035 17.7915 116.93 17.3748C117.826 16.9373 118.743 16.7186 119.68 16.7186C120.868 16.7186 121.836 16.8852 122.586 17.2186C123.357 17.531 123.961 17.9789 124.399 18.5623C124.836 19.1247 125.138 19.8226 125.305 20.6559C125.492 21.4684 125.586 22.3746 125.586 23.3746V33.3117H121.149V24.187C121.149 22.8537 120.941 21.8642 120.524 21.2184C120.107 20.5518 119.368 20.2184 118.305 20.2184C117.097 20.2184 116.222 20.583 115.68 21.3121C115.139 22.0204 114.868 23.1975 114.868 24.8432V33.3117H110.431V17.1561Z"
        fill={textColor}
      />
      <path
        d="M106.937 14.6561H102.499V11H106.937V14.6561ZM102.499 17.156H106.937V33.3116H102.499V17.156Z"
        fill={textColor}
      />
      <path
        d="M86.9061 21.4996H90.7184C91.2809 21.4996 91.8225 21.458 92.3433 21.3746C92.8642 21.2913 93.3225 21.135 93.7183 20.9059C94.1141 20.6559 94.4266 20.3122 94.6557 19.8747C94.9057 19.4372 95.0307 18.8643 95.0307 18.156C95.0307 17.4477 94.9057 16.8748 94.6557 16.4373C94.4266 15.9998 94.1141 15.6665 93.7183 15.4374C93.3225 15.1874 92.8642 15.0207 92.3433 14.9374C91.8225 14.8541 91.2809 14.8124 90.7184 14.8124H86.9061V21.4996ZM82 11H92.0621C93.4579 11 94.6453 11.2084 95.6245 11.625C96.6036 12.0208 97.3952 12.5521 97.9994 13.2187C98.6243 13.8853 99.0722 14.6457 99.3431 15.4999C99.6347 16.354 99.7805 17.2394 99.7805 18.156C99.7805 19.0518 99.6347 19.9372 99.3431 20.8121C99.0722 21.6663 98.6243 22.4267 97.9994 23.0933C97.3952 23.7599 96.6036 24.3016 95.6245 24.7182C94.6453 25.1141 93.4579 25.312 92.0621 25.312H86.9061V33.3116H82V11Z"
        fill={textColor}
      />
      <path
        d="M135.06 54.7031C135.06 54.0156 134.987 53.3594 134.841 52.7344C134.696 52.1094 134.466 51.5574 134.154 51.0782C133.841 50.5991 133.446 50.2241 132.967 49.9533C132.508 49.6616 131.946 49.5158 131.279 49.5158C130.633 49.5158 130.071 49.6616 129.592 49.9533C129.113 50.2241 128.717 50.5991 128.404 51.0782C128.092 51.5574 127.863 52.1094 127.717 52.7344C127.571 53.3594 127.498 54.0156 127.498 54.7031C127.498 55.3697 127.571 56.0155 127.717 56.6405C127.863 57.2655 128.092 57.8175 128.404 58.2967C128.717 58.7758 129.113 59.1612 129.592 59.4529C130.071 59.7237 130.633 59.8591 131.279 59.8591C131.946 59.8591 132.508 59.7237 132.967 59.4529C133.446 59.1612 133.841 58.7758 134.154 58.2967C134.466 57.8175 134.696 57.2655 134.841 56.6405C134.987 56.0155 135.06 55.3697 135.06 54.7031ZM123.217 40.4537H127.654V48.5783H127.717C128.258 47.745 128.988 47.1409 129.904 46.7659C130.842 46.3701 131.81 46.1722 132.81 46.1722C133.623 46.1722 134.425 46.3388 135.216 46.6722C136.008 47.0055 136.716 47.5159 137.341 48.2033C137.987 48.8908 138.508 49.7762 138.904 50.8595C139.3 51.9219 139.497 53.1927 139.497 54.6718C139.497 56.1509 139.3 57.4321 138.904 58.5154C138.508 59.5779 137.987 60.4528 137.341 61.1403C136.716 61.8278 136.008 62.3382 135.216 62.6715C134.425 63.0048 133.623 63.1715 132.81 63.1715C131.623 63.1715 130.56 62.984 129.623 62.609C128.685 62.234 127.977 61.5986 127.498 60.7028H127.435V62.7652H123.217V40.4537Z"
        fill={textColor}
      />
      <path
        d="M119.849 62.7652H115.63V60.5153H115.537C114.974 61.4527 114.245 62.1298 113.349 62.5465C112.454 62.9631 111.537 63.1714 110.599 63.1714C109.412 63.1714 108.433 63.0152 107.662 62.7027C106.912 62.3902 106.318 61.9527 105.881 61.3902C105.443 60.8069 105.131 60.109 104.943 59.2966C104.777 58.4633 104.693 57.5467 104.693 56.5467V46.6096H109.131V55.7342C109.131 57.0675 109.339 58.0675 109.756 58.7341C110.172 59.3799 110.912 59.7028 111.974 59.7028C113.183 59.7028 114.058 59.3487 114.599 58.6404C115.141 57.9112 115.412 56.7238 115.412 55.078V46.6096H119.849V62.7652Z"
        fill={textColor}
      />
      <path
        d="M82 40.4537H86.9061V49.0158H95.9369V40.4537H100.843V62.7652H95.9369V53.1406H86.9061V62.7652H82V40.4537Z"
        fill={textColor}
      />
      <defs>
        <filter
          id="filter0_f_1_255"
          x="0"
          y="0"
          width="74"
          height="74"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_1_255" />
        </filter>
      </defs>
    </svg>
  )
}
