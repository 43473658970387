import { FC } from 'react'

export const PinkhubMesh: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="83" height="42" viewBox="0 0 83 42" fill="none">
      <mask
        id="mask0_35_314"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="83"
        height="42"
      >
        <rect width="83" height="42" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_35_314)">
        <g opacity="0.7" filter="url(#filter0_f_35_314)">
          <path
            d="M85 41.3561C85 43.9242 82.9242 46 80.3561 46L39.6439 46C37.0833 46 35 43.9242 35 41.3561L35 0.643936C35 -1.91667 37.0758 -4 39.6439 -4L80.3561 -4C82.9167 -4 85 -1.92424 85 0.64394L85 41.3561Z"
            fill="#CCA2B1"
          />
          <path
            d="M81.9015 42.4318C82.947 42.4318 83.9621 42.303 84.9394 42.0833C84.9773 41.8484 85 41.606 85 41.356L85 15.1818C84 14.9545 82.9697 14.8181 81.9015 14.8181C74.2803 14.8181 68.0985 20.9999 68.0985 28.6212C68.0985 36.2424 74.2727 42.4318 81.9015 42.4318Z"
            fill="#91477C"
          />
          <path
            d="M73.2045 30.2879C77.5303 30.2879 81.5606 29.0682 84.9924 26.9546L84.9924 0.643967C84.9924 -1.91664 82.9167 -3.99998 80.3485 -3.99998L54.0379 -3.99998C51.9242 -0.575738 50.7045 3.46214 50.7045 7.7879C50.7045 20.2121 60.7803 30.2879 73.2045 30.2879Z"
            fill="#FF2768"
          />
          <path
            d="M39.6438 46L48.2878 46C51.0832 43.4773 52.8484 39.8333 52.8484 35.7652C52.8484 28.1439 46.6666 21.9621 39.0454 21.9621C37.6363 21.9621 36.2802 22.1742 35.0075 22.5682L35.0075 41.3561C35.0075 43.9167 37.0832 46 39.6514 46L39.6438 46Z"
            fill="#91477C"
          />
          <path
            d="M36.6213 6.10605C36.6213 1.4318 40.4092 -2.36365 45.091 -2.36365C49.7728 -2.36365 53.5607 1.42423 53.5607 6.10605C53.5607 10.7879 49.7728 14.5757 45.091 14.5757C40.4092 14.5757 36.6213 10.7879 36.6213 6.10605Z"
            fill="#91477C"
          />
          <path
            d="M56.0303 37.2348C56.0303 34.3636 58.356 32.0378 61.2272 32.0378C64.0984 32.0378 66.4242 34.3636 66.4242 37.2348C66.4242 40.106 64.0984 42.4318 61.2272 42.4318C58.356 42.4318 56.0303 40.106 56.0303 37.2348Z"
            fill="#721743"
          />
          <path
            d="M36.0304 34.3788C36.0304 31.5076 38.3561 29.1818 41.2273 29.1818C44.0986 29.1818 46.4243 31.5076 46.4243 34.3788C46.4243 37.25 44.0986 39.5757 41.2273 39.5757C38.3561 39.5757 36.0304 37.25 36.0304 34.3788Z"
            fill="#721743"
          />
          <path
            d="M67.9015 39.9546C67.9015 38.197 69.3257 36.7652 71.0909 36.7652C72.856 36.7652 74.2803 38.1894 74.2803 39.9546C74.2803 41.7197 72.856 43.144 71.0909 43.144C69.3257 43.144 67.9015 41.7197 67.9015 39.9546Z"
            fill="#721743"
          />
          <path
            d="M35.7576 17.8106C35.7576 16.053 37.1818 14.6212 38.947 14.6212C40.7121 14.6212 42.1364 16.0455 42.1364 17.8106C42.1364 19.5758 40.7121 21 38.947 21C37.1818 21 35.7576 19.5758 35.7576 17.8106Z"
            fill="#FF2768"
          />
          <path
            d="M49.3258 1.38629C49.3258 -0.371286 50.75 -1.8031 52.5152 -1.8031C54.2803 -1.8031 55.7046 -0.378861 55.7046 1.38629C55.7046 3.15144 54.2803 4.57568 52.5152 4.57568C50.75 4.57568 49.3258 3.15144 49.3258 1.38629Z"
            fill="#721743"
          />
          <path
            d="M65.0378 22.0984C65.0378 20.3409 66.4621 18.9091 68.2272 18.9091C69.9924 18.9091 71.4166 20.3333 71.4166 22.0984C71.4166 23.8636 69.9924 25.2878 68.2272 25.2878C66.4621 25.2878 65.0378 23.8636 65.0378 22.0984Z"
            fill="#EDAEAE"
          />
          <path
            d="M60.3107 2.94695C60.3107 0.075738 62.6364 -2.25002 65.5076 -2.25002C68.3788 -2.25002 70.7046 0.0757389 70.7046 2.94695C70.7046 5.81816 68.3788 8.14392 65.5076 8.14392C62.6364 8.14392 60.3107 5.81816 60.3107 2.94695Z"
            fill="#721743"
          />
          <path
            d="M48.7652 30.3864C48.7652 25.7121 52.5531 21.9167 57.2349 21.9167C61.9167 21.9167 65.7046 25.7046 65.7046 30.3864C65.7046 35.0682 61.9167 38.8561 57.2349 38.8561C52.5531 38.8561 48.7652 35.0682 48.7652 30.3864Z"
            fill="#FF2768"
          />
          <path
            d="M39.5985 15.0909C39.5985 12.2197 41.9243 9.89396 44.7955 9.89396C47.6667 9.89396 49.9924 12.2197 49.9924 15.0909C49.9924 17.9621 47.6667 20.2879 44.7955 20.2879C41.9242 20.2879 39.5985 17.9621 39.5985 15.0909Z"
            fill="#EDAEAE"
          />
          <path
            d="M71.4696 7.81055C71.4696 6.05297 72.8938 4.62115 74.659 4.62115C76.4241 4.62115 77.8484 6.04539 77.8484 7.81055C77.8484 9.5757 76.4241 10.9999 74.659 10.9999C72.8938 10.9999 71.4696 9.5757 71.4696 7.81055Z"
            fill="#721743"
          />
          <path
            d="M69.0151 11.9394C69.0151 11.2727 69.553 10.7424 70.2121 10.7424C70.8712 10.7424 71.4091 11.2803 71.4091 11.9394C71.4091 12.5985 70.8712 13.1364 70.2121 13.1364C69.553 13.1364 69.0151 12.5985 69.0151 11.9394Z"
            fill="#721743"
          />
          <path
            d="M84.0758 31C84.3939 31 84.697 30.9697 85 30.9167L85 20.697C84.697 20.6439 84.3939 20.6136 84.0758 20.6136C81.2045 20.6136 78.8788 22.9394 78.8788 25.8106C78.8788 28.6818 81.2045 31.0076 84.0758 31.0076L84.0758 31Z"
            fill="#721743"
          />
          <path
            d="M56.0227 13.6591C56.0227 10.7879 58.3484 8.46214 61.2197 8.46214C64.0909 8.46214 66.4166 10.7879 66.4166 13.6591C66.4166 16.5303 64.0909 18.8561 61.2197 18.8561C58.3484 18.8561 56.0227 16.5303 56.0227 13.6591Z"
            fill="#FBD2D2"
          />
          <path
            d="M51.7879 19.0455C51.7879 17.9621 52.6667 17.0909 53.7425 17.0909C54.8182 17.0909 55.697 17.9697 55.697 19.0455C55.697 20.1212 54.8182 21 53.7425 21C52.6667 21 51.7879 20.1212 51.7879 19.0455Z"
            fill="#FBD2D2"
          />
          <path
            d="M49.7273 24.0833C49.7273 23.4166 50.2652 22.8863 50.9242 22.8863C51.5833 22.8863 52.1212 23.4242 52.1212 24.0833C52.1212 24.7424 51.5833 25.2803 50.9242 25.2803C50.2652 25.2803 49.7273 24.7424 49.7273 24.0833Z"
            fill="#EDAEAE"
          />
          <path
            d="M52.9016 33.5226C52.9016 31.7651 54.3259 30.3332 56.091 30.3332C57.8562 30.3332 59.2804 31.7575 59.2804 33.5226C59.2804 35.2878 57.8562 36.712 56.091 36.712C54.3259 36.712 52.9016 35.2878 52.9016 33.5226Z"
            fill="#FFCD1E"
          />
          <path
            d="M40.7576 38.5227C40.7576 36.7651 42.1818 35.3333 43.947 35.3333C45.7121 35.3333 47.1364 36.7576 47.1364 38.5227C47.1364 40.2879 45.7121 41.7121 43.947 41.7121C42.1818 41.7121 40.7576 40.2879 40.7576 38.5227Z"
            fill="#FBD2D2"
          />
          <path
            d="M42.1819 3.5227C42.1819 1.76513 43.6061 0.33331 45.3713 0.33331C47.1364 0.33331 48.5607 1.75755 48.5607 3.5227C48.5607 5.28786 47.1364 6.7121 45.3713 6.7121C43.6061 6.7121 42.1819 5.28785 42.1819 3.5227Z"
            fill="#FFCD1E"
          />
          <path
            d="M71.7348 32.2273C71.7348 29.3561 74.0606 27.0303 76.9318 27.0303C79.803 27.0303 82.1288 29.3561 82.1288 32.2273C82.1288 35.0985 79.803 37.4243 76.9318 37.4243C74.0606 37.4243 71.7348 35.0985 71.7348 32.2273Z"
            fill="#FFCD1E"
          />
          <path
            d="M39.644 46L48.4167 46C47.4925 39.3636 41.8637 34.2424 35.0077 34.1212L35.0077 41.3561C35.0077 43.9167 37.0834 46 39.6516 46L39.644 46Z"
            fill="#FF2768"
          />
          <path
            d="M74.9621 46L80.3561 46C82.9242 46 85 43.9242 85 41.3561L85 39.25C80.7045 39.8409 77.0455 42.4015 74.9621 46Z"
            fill="#FF2768"
          />
          <path
            d="M80.803 24.5682C82.3333 24.5682 83.7652 24.1591 85 23.447L85 8.74248C83.7652 8.03794 82.3333 7.62127 80.803 7.62127C76.1288 7.62127 72.3333 11.4167 72.3333 16.091C72.3333 20.7652 76.1288 24.5607 80.803 24.5607L80.803 24.5682Z"
            fill="#FBD2D2"
          />
          <path
            d="M40.7046 6.52276C40.7046 3.65155 38.3788 1.32579 35.5076 1.32579C35.3334 1.32579 35.1667 1.33337 35 1.34852L35 11.6894C35.1667 11.7046 35.3334 11.7122 35.5076 11.7122C38.3788 11.7122 40.7046 9.3864 40.7046 6.51519L40.7046 6.52276Z"
            fill="#FBD2D2"
          />
        </g>
        <g opacity="0.9">
          <path
            d="M81.9015 42.4318C82.947 42.4318 83.9621 42.303 84.9394 42.0833C84.9773 41.8484 85 41.606 85 41.356L85 15.1818C84 14.9545 82.9697 14.8181 81.9015 14.8181C74.2803 14.8181 68.0985 20.9999 68.0985 28.6212C68.0985 36.2424 74.2727 42.4318 81.9015 42.4318Z"
            fill="#91477C"
          />
          <path
            d="M73.2045 30.2879C77.5303 30.2879 81.5606 29.0682 84.9924 26.9546L84.9924 0.643967C84.9924 -1.91664 82.9167 -3.99998 80.3485 -3.99998L54.0379 -3.99998C51.9242 -0.575738 50.7045 3.46214 50.7045 7.7879C50.7045 20.2121 60.7803 30.2879 73.2045 30.2879Z"
            fill="#FF2768"
          />
          <path
            d="M39.6438 46L48.2878 46C51.0832 43.4773 52.8484 39.8333 52.8484 35.7652C52.8484 28.1439 46.6666 21.9621 39.0454 21.9621C37.6363 21.9621 36.2802 22.1742 35.0075 22.5682L35.0075 41.3561C35.0075 43.9167 37.0832 46 39.6514 46L39.6438 46Z"
            fill="#91477C"
          />
          <path
            d="M36.6213 6.10605C36.6213 1.4318 40.4092 -2.36365 45.091 -2.36365C49.7728 -2.36365 53.5607 1.42423 53.5607 6.10605C53.5607 10.7879 49.7728 14.5757 45.091 14.5757C40.4092 14.5757 36.6213 10.7879 36.6213 6.10605Z"
            fill="#91477C"
          />
          <path
            d="M56.0303 37.2348C56.0303 34.3636 58.356 32.0378 61.2272 32.0378C64.0984 32.0378 66.4242 34.3636 66.4242 37.2348C66.4242 40.106 64.0984 42.4318 61.2272 42.4318C58.356 42.4318 56.0303 40.106 56.0303 37.2348Z"
            fill="#721743"
          />
          <path
            d="M36.0304 34.3788C36.0304 31.5076 38.3561 29.1818 41.2273 29.1818C44.0986 29.1818 46.4243 31.5076 46.4243 34.3788C46.4243 37.25 44.0986 39.5757 41.2273 39.5757C38.3561 39.5757 36.0304 37.25 36.0304 34.3788Z"
            fill="#721743"
          />
          <path
            d="M75 5.18939C75 3.43181 76.4242 2 78.1894 2C79.9545 2 81.3788 3.42424 81.3788 5.18939C81.3788 6.95454 79.9545 8.37878 78.1894 8.37878C76.4242 8.37878 75 6.95454 75 5.18939Z"
            fill="#721743"
          />
          <path
            d="M35.7576 17.8106C35.7576 16.053 37.1818 14.6212 38.947 14.6212C40.7121 14.6212 42.1364 16.0455 42.1364 17.8106C42.1364 19.5758 40.7121 21 38.947 21C37.1818 21 35.7576 19.5758 35.7576 17.8106Z"
            fill="#FF2768"
          />
          <path
            d="M49.3258 1.38629C49.3258 -0.371286 50.75 -1.8031 52.5152 -1.8031C54.2803 -1.8031 55.7046 -0.378861 55.7046 1.38629C55.7046 3.15144 54.2803 4.57568 52.5152 4.57568C50.75 4.57568 49.3258 3.15144 49.3258 1.38629Z"
            fill="#721743"
          />
          <path
            d="M65.0378 22.0984C65.0378 20.3409 66.4621 18.9091 68.2272 18.9091C69.9924 18.9091 71.4166 20.3333 71.4166 22.0984C71.4166 23.8636 69.9924 25.2878 68.2272 25.2878C66.4621 25.2878 65.0378 23.8636 65.0378 22.0984Z"
            fill="#EDAEAE"
          />
          <path
            d="M60.3107 2.94695C60.3107 0.075738 62.6364 -2.25002 65.5076 -2.25002C68.3788 -2.25002 70.7046 0.0757389 70.7046 2.94695C70.7046 5.81816 68.3788 8.14392 65.5076 8.14392C62.6364 8.14392 60.3107 5.81816 60.3107 2.94695Z"
            fill="#721743"
          />
          <path
            d="M48.7652 30.3864C48.7652 25.7121 52.5531 21.9167 57.2349 21.9167C61.9167 21.9167 65.7046 25.7046 65.7046 30.3864C65.7046 35.0682 61.9167 38.8561 57.2349 38.8561C52.5531 38.8561 48.7652 35.0682 48.7652 30.3864Z"
            fill="#FF2768"
          />
          <path
            d="M39.5985 15.0909C39.5985 12.2197 41.9243 9.89396 44.7955 9.89396C47.6667 9.89396 49.9924 12.2197 49.9924 15.0909C49.9924 17.9621 47.6667 20.2879 44.7955 20.2879C41.9242 20.2879 39.5985 17.9621 39.5985 15.0909Z"
            fill="#EDAEAE"
          />
          <path
            d="M60 14.1894C60 12.4318 61.4242 11 63.1894 11C64.9545 11 66.3788 12.4242 66.3788 14.1894C66.3788 15.9545 64.9545 17.3788 63.1894 17.3788C61.4242 17.3788 60 15.9545 60 14.1894Z"
            fill="#721743"
          />
          <path
            d="M69.0151 11.9394C69.0151 11.2727 69.553 10.7424 70.2121 10.7424C70.8712 10.7424 71.4091 11.2803 71.4091 11.9394C71.4091 12.5985 70.8712 13.1364 70.2121 13.1364C69.553 13.1364 69.0151 12.5985 69.0151 11.9394Z"
            fill="#721743"
          />
          <path
            d="M84.0758 31C84.3939 31 84.697 30.9697 85 30.9167L85 20.697C84.697 20.6439 84.3939 20.6136 84.0758 20.6136C81.2045 20.6136 78.8788 22.9394 78.8788 25.8106C78.8788 28.6818 81.2045 31.0076 84.0758 31.0076L84.0758 31Z"
            fill="#721743"
          />
          <path
            d="M72 17.197C72 14.3257 74.3257 12 77.197 12C80.0682 12 82.3939 14.3257 82.3939 17.197C82.3939 20.0682 80.0682 22.3939 77.197 22.3939C74.3257 22.3939 72 20.0682 72 17.197Z"
            fill="#FBD2D2"
          />
          <path
            d="M51.7879 19.0455C51.7879 17.9621 52.6667 17.0909 53.7425 17.0909C54.8182 17.0909 55.697 17.9697 55.697 19.0455C55.697 20.1212 54.8182 21 53.7425 21C52.6667 21 51.7879 20.1212 51.7879 19.0455Z"
            fill="#FBD2D2"
          />
          <path
            d="M49.7273 24.0833C49.7273 23.4166 50.2652 22.8863 50.9242 22.8863C51.5833 22.8863 52.1212 23.4242 52.1212 24.0833C52.1212 24.7424 51.5833 25.2803 50.9242 25.2803C50.2652 25.2803 49.7273 24.7424 49.7273 24.0833Z"
            fill="#EDAEAE"
          />
          <path
            d="M52.9016 33.5226C52.9016 31.7651 54.3259 30.3332 56.091 30.3332C57.8562 30.3332 59.2804 31.7575 59.2804 33.5226C59.2804 35.2878 57.8562 36.712 56.091 36.712C54.3259 36.712 52.9016 35.2878 52.9016 33.5226Z"
            fill="#FFCD1E"
          />
          <path
            d="M40.7576 38.5227C40.7576 36.7651 42.1818 35.3333 43.947 35.3333C45.7121 35.3333 47.1364 36.7576 47.1364 38.5227C47.1364 40.2879 45.7121 41.7121 43.947 41.7121C42.1818 41.7121 40.7576 40.2879 40.7576 38.5227Z"
            fill="#FBD2D2"
          />
          <path
            d="M42.1819 3.5227C42.1819 1.76513 43.6061 0.33331 45.3713 0.33331C47.1364 0.33331 48.5607 1.75755 48.5607 3.5227C48.5607 5.28786 47.1364 6.7121 45.3713 6.7121C43.6061 6.7121 42.1819 5.28785 42.1819 3.5227Z"
            fill="#FFCD1E"
          />
          <path
            d="M71.7348 32.2273C71.7348 29.3561 74.0606 27.0303 76.9318 27.0303C79.803 27.0303 82.1288 29.3561 82.1288 32.2273C82.1288 35.0985 79.803 37.4243 76.9318 37.4243C74.0606 37.4243 71.7348 35.0985 71.7348 32.2273Z"
            fill="#FFCD1E"
          />
          <path
            d="M39.644 46L48.4167 46C47.4925 39.3636 41.8637 34.2424 35.0077 34.1212L35.0077 41.3561C35.0077 43.9167 37.0834 46 39.6516 46L39.644 46Z"
            fill="#FF2768"
          />
          <path
            d="M74.9621 46L80.3561 46C82.9242 46 85 43.9242 85 41.3561L85 39.25C80.7045 39.8409 77.0455 42.4015 74.9621 46Z"
            fill="#FF2768"
          />
          <path
            d="M40.7046 6.52276C40.7046 3.65155 38.3788 1.32579 35.5076 1.32579C35.3334 1.32579 35.1667 1.33337 35 1.34852L35 11.6894C35.1667 11.7046 35.3334 11.7122 35.5076 11.7122C38.3788 11.7122 40.7046 9.3864 40.7046 6.51519L40.7046 6.52276Z"
            fill="#FBD2D2"
          />
        </g>
        <g opacity="0.7" filter="url(#filter1_f_35_314)">
          <path
            d="M0 0.643939C0 -1.92424 2.07576 -4 4.64394 -4H45.3561C47.9167 -4 50 -1.92424 50 0.643939V41.3561C50 43.9167 47.9242 46 45.3561 46H4.64394C2.08333 46 0 43.9242 0 41.3561V0.643939Z"
            fill="#CCA2B1"
          />
          <path
            d="M3.09849 -0.431763C2.05303 -0.431763 1.03788 -0.302975 0.060606 -0.0832778C0.0227272 0.151571 0 0.393995 0 0.643995V26.8182C1 27.0455 2.0303 27.1819 3.09849 27.1819C10.7197 27.1819 16.9015 21.0001 16.9015 13.3788C16.9015 5.75763 10.7273 -0.431763 3.09849 -0.431763Z"
            fill="#91477C"
          />
          <path
            d="M11.7955 11.7121C7.4697 11.7121 3.43939 12.9318 0.00756836 15.0454V41.356C0.00756836 43.9166 2.08333 46 4.65152 46H30.9621C33.0758 42.5757 34.2955 38.5379 34.2955 34.2121C34.2955 21.7879 24.2197 11.7121 11.7955 11.7121Z"
            fill="#FF2768"
          />
          <path
            d="M45.3562 -4H36.7122C33.9168 -1.47727 32.1516 2.16667 32.1516 6.23485C32.1516 13.8561 38.3334 20.0379 45.9546 20.0379C47.3637 20.0379 48.7198 19.8258 49.9925 19.4318V0.643939C49.9925 -1.91667 47.9168 -4 45.3486 -4H45.3562Z"
            fill="#91477C"
          />
          <path
            d="M48.3787 35.894C48.3787 40.5682 44.5908 44.3636 39.909 44.3636C35.2272 44.3636 31.4393 40.5758 31.4393 35.894C31.4393 31.2121 35.2272 27.4243 39.909 27.4243C44.5908 27.4243 48.3787 31.2121 48.3787 35.894Z"
            fill="#91477C"
          />
          <path
            d="M28.9697 4.76521C28.9697 7.63642 26.6439 9.96218 23.7727 9.96218C20.9015 9.96218 18.5757 7.63642 18.5757 4.76521C18.5757 1.89399 20.9015 -0.431763 23.7727 -0.431763C26.6439 -0.431763 28.9697 1.89399 28.9697 4.76521Z"
            fill="#721743"
          />
          <path
            d="M48.9696 7.62123C48.9696 10.4924 46.6439 12.8182 43.7727 12.8182C40.9014 12.8182 38.5757 10.4924 38.5757 7.62123C38.5757 4.75001 40.9014 2.42426 43.7727 2.42426C46.6439 2.42426 48.9696 4.75001 48.9696 7.62123Z"
            fill="#721743"
          />
          <path
            d="M17.0985 2.04541C17.0985 3.80299 15.6742 5.23481 13.9091 5.23481C12.1439 5.23481 10.7197 3.81056 10.7197 2.04541C10.7197 0.28026 12.1439 -1.14398 13.9091 -1.14398C15.6742 -1.14398 17.0985 0.28026 17.0985 2.04541Z"
            fill="#721743"
          />
          <path
            d="M49.2424 24.1894C49.2424 25.947 47.8182 27.3788 46.053 27.3788C44.2879 27.3788 42.8636 25.9545 42.8636 24.1894C42.8636 22.4242 44.2879 21 46.053 21C47.8182 21 49.2424 22.4242 49.2424 24.1894Z"
            fill="#FF2768"
          />
          <path
            d="M35.6742 40.6137C35.6742 42.3713 34.25 43.8031 32.4848 43.8031C30.7197 43.8031 29.2954 42.3789 29.2954 40.6137C29.2954 38.8486 30.7197 37.4243 32.4848 37.4243C34.25 37.4243 35.6742 38.8486 35.6742 40.6137Z"
            fill="#721743"
          />
          <path
            d="M19.9622 19.9016C19.9622 21.6591 18.5379 23.0909 16.7728 23.0909C15.0076 23.0909 13.5834 21.6667 13.5834 19.9016C13.5834 18.1364 15.0076 16.7122 16.7728 16.7122C18.5379 16.7122 19.9622 18.1364 19.9622 19.9016Z"
            fill="#EDAEAE"
          />
          <path
            d="M24.6894 39.053C24.6894 41.9243 22.3637 44.25 19.4924 44.25C16.6212 44.25 14.2955 41.9243 14.2955 39.053C14.2955 36.1818 16.6212 33.8561 19.4924 33.8561C22.3637 33.8561 24.6894 36.1818 24.6894 39.053Z"
            fill="#721743"
          />
          <path
            d="M36.2348 11.6136C36.2348 16.2879 32.4469 20.0833 27.7651 20.0833C23.0833 20.0833 19.2954 16.2954 19.2954 11.6136C19.2954 6.9318 23.0833 3.14392 27.7651 3.14392C32.4469 3.14392 36.2348 6.9318 36.2348 11.6136Z"
            fill="#FF2768"
          />
          <path
            d="M45.4015 26.9091C45.4015 29.7803 43.0758 32.106 40.2045 32.106C37.3333 32.106 35.0076 29.7803 35.0076 26.9091C35.0076 24.0379 37.3333 21.7121 40.2045 21.7121C43.0758 21.7121 45.4015 24.0379 45.4015 26.9091Z"
            fill="#EDAEAE"
          />
          <path
            d="M13.5303 34.1895C13.5303 35.947 12.1061 37.3788 10.3409 37.3788C8.57579 37.3788 7.15155 35.9546 7.15155 34.1895C7.15155 32.4243 8.57579 31.0001 10.3409 31.0001C12.1061 31.0001 13.5303 32.4243 13.5303 34.1895Z"
            fill="#721743"
          />
          <path
            d="M15.9849 30.0606C15.9849 30.7273 15.447 31.2576 14.7879 31.2576C14.1288 31.2576 13.5909 30.7197 13.5909 30.0606C13.5909 29.4015 14.1288 28.8636 14.7879 28.8636C15.447 28.8636 15.9849 29.4015 15.9849 30.0606Z"
            fill="#721743"
          />
          <path
            d="M0.924242 11C0.606061 11 0.30303 11.0303 0 11.0833V21.303C0.30303 21.3561 0.606061 21.3864 0.924242 21.3864C3.79545 21.3864 6.12121 19.0606 6.12121 16.1894C6.12121 13.3182 3.79545 10.9924 0.924242 10.9924V11Z"
            fill="#721743"
          />
          <path
            d="M28.9773 28.3409C28.9773 31.2121 26.6515 33.5379 23.7803 33.5379C20.9091 33.5379 18.5833 31.2121 18.5833 28.3409C18.5833 25.4697 20.9091 23.1439 23.7803 23.1439C26.6515 23.1439 28.9773 25.4697 28.9773 28.3409Z"
            fill="#FBD2D2"
          />
          <path
            d="M33.2121 22.9545C33.2121 24.0379 32.3333 24.9091 31.2575 24.9091C30.1818 24.9091 29.303 24.0303 29.303 22.9545C29.303 21.8788 30.1818 21 31.2575 21C32.3333 21 33.2121 21.8788 33.2121 22.9545Z"
            fill="#FBD2D2"
          />
          <path
            d="M35.2727 17.9167C35.2727 18.5834 34.7348 19.1137 34.0758 19.1137C33.4167 19.1137 32.8788 18.5758 32.8788 17.9167C32.8788 17.2576 33.4167 16.7197 34.0758 16.7197C34.7348 16.7197 35.2727 17.2576 35.2727 17.9167Z"
            fill="#EDAEAE"
          />
          <path
            d="M32.0984 8.47736C32.0984 10.2349 30.6741 11.6668 28.909 11.6668C27.1438 11.6668 25.7196 10.2425 25.7196 8.47736C25.7196 6.71221 27.1438 5.28796 28.909 5.28796C30.6741 5.28796 32.0984 6.71221 32.0984 8.47736Z"
            fill="#FFCD1E"
          />
          <path
            d="M44.2424 3.47724C44.2424 5.23481 42.8182 6.66663 41.053 6.66663C39.2879 6.66663 37.8636 5.24239 37.8636 3.47724C37.8636 1.71208 39.2879 0.287842 41.053 0.287842C42.8182 0.287842 44.2424 1.71208 44.2424 3.47724Z"
            fill="#FBD2D2"
          />
          <path
            d="M42.8181 38.4773C42.8181 40.2349 41.3939 41.6667 39.6287 41.6667C37.8636 41.6667 36.4393 40.2424 36.4393 38.4773C36.4393 36.7121 37.8636 35.2879 39.6287 35.2879C41.3939 35.2879 42.8181 36.7121 42.8181 38.4773Z"
            fill="#FFCD1E"
          />
          <path
            d="M13.2652 9.77271C13.2652 12.6439 10.9394 14.9697 8.06819 14.9697C5.19697 14.9697 2.87122 12.6439 2.87122 9.77271C2.87122 6.9015 5.19697 4.57574 8.06819 4.57574C10.9394 4.57574 13.2652 6.9015 13.2652 9.77271Z"
            fill="#FFCD1E"
          />
          <path
            d="M45.356 -4H36.5833C37.5075 2.63636 43.1363 7.75758 49.9923 7.87879V0.643939C49.9923 -1.91667 47.9166 -4 45.3484 -4H45.356Z"
            fill="#FF2768"
          />
          <path
            d="M10.0379 -4H4.64394C2.07576 -4 0 -1.92424 0 0.64394V2.75C4.29545 2.15909 7.95455 -0.401515 10.0379 -4Z"
            fill="#FF2768"
          />
          <path
            d="M4.19697 17.4318C2.66667 17.4318 1.23485 17.8409 0 18.553V33.2575C1.23485 33.9621 2.66667 34.3787 4.19697 34.3787C8.87121 34.3787 12.6667 30.5833 12.6667 25.909C12.6667 21.2348 8.87121 17.4393 4.19697 17.4393V17.4318Z"
            fill="#FBD2D2"
          />
          <path
            d="M44.2954 35.4772C44.2954 38.3484 46.6212 40.6742 49.4924 40.6742C49.6666 40.6742 49.8333 40.6666 50 40.6515V30.3106C49.8333 30.2954 49.6666 30.2878 49.4924 30.2878C46.6212 30.2878 44.2954 32.6136 44.2954 35.4848V35.4772Z"
            fill="#FBD2D2"
          />
        </g>
        <g opacity="0.9">
          <path
            d="M3.09849 -0.431763C2.05303 -0.431763 1.03788 -0.302975 0.060606 -0.0832778C0.0227272 0.151571 0 0.393995 0 0.643995V26.8182C1 27.0455 2.0303 27.1819 3.09849 27.1819C10.7197 27.1819 16.9015 21.0001 16.9015 13.3788C16.9015 5.75763 10.7273 -0.431763 3.09849 -0.431763Z"
            fill="#91477C"
          />
          <path
            d="M11.7955 11.7121C7.4697 11.7121 3.43939 12.9318 0.00756836 15.0454V41.356C0.00756836 43.9166 2.08333 46 4.65152 46H30.9621C33.0758 42.5757 34.2955 38.5379 34.2955 34.2121C34.2955 21.7879 24.2197 11.7121 11.7955 11.7121Z"
            fill="#FF2768"
          />
          <path
            d="M48.3787 35.894C48.3787 40.5682 44.5908 44.3636 39.909 44.3636C35.2272 44.3636 31.4393 40.5758 31.4393 35.894C31.4393 31.2121 35.2272 27.4243 39.909 27.4243C44.5908 27.4243 48.3787 31.2121 48.3787 35.894Z"
            fill="#91477C"
          />
          <path
            d="M28.9697 4.76521C28.9697 7.63642 26.6439 9.96218 23.7727 9.96218C20.9015 9.96218 18.5757 7.63642 18.5757 4.76521C18.5757 1.89399 20.9015 -0.431763 23.7727 -0.431763C26.6439 -0.431763 28.9697 1.89399 28.9697 4.76521Z"
            fill="#721743"
          />
          <path
            d="M50 14.5C50 18.0911 47.0911 21 43.5 21C39.9089 21 37 18.0911 37 14.5C37 10.9089 39.9089 8 43.5 8C47.0911 8 50 10.9089 50 14.5Z"
            fill="#721743"
          />
          <path
            d="M17.0985 2.04541C17.0985 3.80299 15.6742 5.23481 13.9091 5.23481C12.1439 5.23481 10.7197 3.81056 10.7197 2.04541C10.7197 0.28026 12.1439 -1.14398 13.9091 -1.14398C15.6742 -1.14398 17.0985 0.28026 17.0985 2.04541Z"
            fill="#721743"
          />
          <path
            d="M49.2424 24.1894C49.2424 25.947 47.8182 27.3788 46.053 27.3788C44.2879 27.3788 42.8636 25.9545 42.8636 24.1894C42.8636 22.4242 44.2879 21 46.053 21C47.8182 21 49.2424 22.4242 49.2424 24.1894Z"
            fill="#FF2768"
          />
          <path
            d="M35.6742 40.6137C35.6742 42.3713 34.25 43.8031 32.4848 43.8031C30.7197 43.8031 29.2954 42.3789 29.2954 40.6137C29.2954 38.8486 30.7197 37.4243 32.4848 37.4243C34.25 37.4243 35.6742 38.8486 35.6742 40.6137Z"
            fill="#721743"
          />
          <path
            d="M19.9622 19.9016C19.9622 21.6591 18.5379 23.0909 16.7728 23.0909C15.0076 23.0909 13.5834 21.6667 13.5834 19.9016C13.5834 18.1364 15.0076 16.7122 16.7728 16.7122C18.5379 16.7122 19.9622 18.1364 19.9622 19.9016Z"
            fill="#EDAEAE"
          />
          <path
            d="M24.6894 39.053C24.6894 41.9243 22.3637 44.25 19.4924 44.25C16.6212 44.25 14.2955 41.9243 14.2955 39.053C14.2955 36.1818 16.6212 33.8561 19.4924 33.8561C22.3637 33.8561 24.6894 36.1818 24.6894 39.053Z"
            fill="#721743"
          />
          <path
            d="M36.2348 11.6136C36.2348 16.2879 32.4469 20.0833 27.7651 20.0833C23.0833 20.0833 19.2954 16.2954 19.2954 11.6136C19.2954 6.9318 23.0833 3.14392 27.7651 3.14392C32.4469 3.14392 36.2348 6.9318 36.2348 11.6136Z"
            fill="#FF2768"
          />
          <path
            d="M45.4015 26.9091C45.4015 29.7803 43.0758 32.106 40.2045 32.106C37.3333 32.106 35.0076 29.7803 35.0076 26.9091C35.0076 24.0379 37.3333 21.7121 40.2045 21.7121C43.0758 21.7121 45.4015 24.0379 45.4015 26.9091Z"
            fill="#EDAEAE"
          />
          <path
            d="M13.5303 34.1895C13.5303 35.947 12.1061 37.3788 10.3409 37.3788C8.57579 37.3788 7.15155 35.9546 7.15155 34.1895C7.15155 32.4243 8.57579 31.0001 10.3409 31.0001C12.1061 31.0001 13.5303 32.4243 13.5303 34.1895Z"
            fill="#721743"
          />
          <path
            d="M15.9849 30.0606C15.9849 30.7273 15.447 31.2576 14.7879 31.2576C14.1288 31.2576 13.5909 30.7197 13.5909 30.0606C13.5909 29.4015 14.1288 28.8636 14.7879 28.8636C15.447 28.8636 15.9849 29.4015 15.9849 30.0606Z"
            fill="#721743"
          />
          <path
            d="M0.924242 11C0.606061 11 0.30303 11.0303 0 11.0833V21.303C0.30303 21.3561 0.606061 21.3864 0.924242 21.3864C3.79545 21.3864 6.12121 19.0606 6.12121 16.1894C6.12121 13.3182 3.79545 10.9924 0.924242 10.9924V11Z"
            fill="#721743"
          />
          <path
            d="M28.9773 28.3409C28.9773 31.2121 26.6515 33.5379 23.7803 33.5379C20.9091 33.5379 18.5833 31.2121 18.5833 28.3409C18.5833 25.4697 20.9091 23.1439 23.7803 23.1439C26.6515 23.1439 28.9773 25.4697 28.9773 28.3409Z"
            fill="#FBD2D2"
          />
          <path
            d="M33.2121 22.9545C33.2121 24.0379 32.3333 24.9091 31.2575 24.9091C30.1818 24.9091 29.303 24.0303 29.303 22.9545C29.303 21.8788 30.1818 21 31.2575 21C32.3333 21 33.2121 21.8788 33.2121 22.9545Z"
            fill="#FBD2D2"
          />
          <path
            d="M35.2727 17.9167C35.2727 18.5834 34.7348 19.1137 34.0758 19.1137C33.4167 19.1137 32.8788 18.5758 32.8788 17.9167C32.8788 17.2576 33.4167 16.7197 34.0758 16.7197C34.7348 16.7197 35.2727 17.2576 35.2727 17.9167Z"
            fill="#EDAEAE"
          />
          <path
            d="M32.0984 8.47736C32.0984 10.2349 30.6741 11.6668 28.909 11.6668C27.1438 11.6668 25.7196 10.2425 25.7196 8.47736C25.7196 6.71221 27.1438 5.28796 28.909 5.28796C30.6741 5.28796 32.0984 6.71221 32.0984 8.47736Z"
            fill="#FFCD1E"
          />
          <path
            d="M43.3788 5.18939C43.3788 6.94697 41.9545 8.37879 40.1894 8.37879C38.4242 8.37879 37 6.95455 37 5.18939C37 3.42424 38.4242 2 40.1894 2C41.9545 2 43.3788 3.42424 43.3788 5.18939Z"
            fill="#FBD2D2"
          />
          <path
            d="M42.8181 38.4773C42.8181 40.2349 41.3939 41.6667 39.6287 41.6667C37.8636 41.6667 36.4393 40.2424 36.4393 38.4773C36.4393 36.7121 37.8636 35.2879 39.6287 35.2879C41.3939 35.2879 42.8181 36.7121 42.8181 38.4773Z"
            fill="#FFCD1E"
          />
          <path
            d="M13.2652 9.77271C13.2652 12.6439 10.9394 14.9697 8.06819 14.9697C5.19697 14.9697 2.87122 12.6439 2.87122 9.77271C2.87122 6.9015 5.19697 4.57574 8.06819 4.57574C10.9394 4.57574 13.2652 6.9015 13.2652 9.77271Z"
            fill="#FFCD1E"
          />
          <path
            d="M10.0379 -4H4.64394C2.07576 -4 0 -1.92424 0 0.64394V2.75C4.29545 2.15909 7.95455 -0.401515 10.0379 -4Z"
            fill="#FF2768"
          />
          <path
            d="M4.19697 17.4318C2.66667 17.4318 1.23485 17.8409 0 18.553V33.2575C1.23485 33.9621 2.66667 34.3787 4.19697 34.3787C8.87121 34.3787 12.6667 30.5833 12.6667 25.909C12.6667 21.2348 8.87121 17.4393 4.19697 17.4393V17.4318Z"
            fill="#FBD2D2"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_35_314"
          x="32"
          y="-7"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_35_314" />
        </filter>
        <filter
          id="filter1_f_35_314"
          x="-3"
          y="-7"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_35_314" />
        </filter>
      </defs>
    </svg>
  )
}
