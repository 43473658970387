import { DetailedHTMLProps, FC, HTMLAttributes, createRef, useContext, useEffect, useState } from 'react'
import {
  ConfigurationList,
  ConfigurationTypeSelection,
  ConfigurationTypeTab,
  ContentLoaderSkeleton,
  LeftSidebarWrapper,
  TopContainer,
} from './LeftSidebar.styles'
import { DraggableBorder } from '@styles/generic.styles'
import { observer } from 'mobx-react'
import { StoreContext } from '@components/App'
import { ConfigurationListItem } from './ConfigurationListItem'
import { SearchInput } from '@components/SearchInput'
import { RefreshButton } from '@components/RefreshButton'
import { ConfigurationsTabTypes } from '@/store/CodeView.store'

interface LeftSidebarProps {
  dragBarProps: Omit<DetailedHTMLProps<HTMLAttributes<HTMLHRElement>, HTMLHRElement>, 'ref'>
  isResizing: boolean
}

export const LeftSidebar: FC<LeftSidebarProps> = observer(({ dragBarProps, isResizing }) => {
  const store = useContext(StoreContext)
  const { clientList, entityList, isConfigFetchLoading } = store.CommunicationState
  const { gridDimensions } = store.UserInterfaceState
  const { configurationsSearchQuery, selectedConfigurationTab } = store.UserInterfaceState.leftSidebar

  const lists = { client: clientList, entity: entityList }

  const configurationListRef = createRef<HTMLUListElement>()
  const [configurationListWidth, setConfigurationListWidth] = useState(0)

  useEffect(() => {
    setConfigurationListWidth(configurationListRef.current?.clientWidth)
  }, [configurationListRef])

  const handleTabChange = (tab: ConfigurationsTabTypes) => {
    store.UserInterfaceState.setSelectedConfigurationTab(tab)
  }

  return (
    <LeftSidebarWrapper>
      <TopContainer>
        <SearchInput
          disabled={!selectedConfigurationTab}
          value={configurationsSearchQuery}
          placeholder="Search by includes"
          onChange={(e) => {
            store.UserInterfaceState.setConfigurationsSearchQuery(e)
          }}
        />
        <RefreshButton
          onClick={() => {
            store.CommunicationState.fetchConfigurationLists()
          }}
          isLoading={isConfigFetchLoading}
        />
      </TopContainer>

      {isConfigFetchLoading ? (
        <ContentLoaderSkeleton type="list" />
      ) : (
        <>
          <ConfigurationTypeSelection $isTabSelected={!!selectedConfigurationTab?.length}>
            <ConfigurationTypeTab
              $active={selectedConfigurationTab === 'client'}
              onClick={() => handleTabChange('client')}
            >
              Clients
            </ConfigurationTypeTab>
            <ConfigurationTypeTab
              $active={selectedConfigurationTab === 'entity'}
              onClick={() => handleTabChange('entity')}
            >
              Entities
            </ConfigurationTypeTab>
          </ConfigurationTypeSelection>

          {!!selectedConfigurationTab?.length && (
            <ConfigurationList $headerHeight={`${gridDimensions?.headerHeight}px`} ref={configurationListRef}>
              {lists[selectedConfigurationTab]
                ?.filter((item) => item.name.toLowerCase().includes(configurationsSearchQuery.toLowerCase()))
                ?.map((item) => (
                  <ConfigurationListItem
                    width={configurationListWidth}
                    key={item.name}
                    title={item.name}
                    type={selectedConfigurationTab}
                  />
                ))}
            </ConfigurationList>
          )}
        </>
      )}

      <DraggableBorder {...dragBarProps} $align="right" $isDragging={isResizing} />
    </LeftSidebarWrapper>
  )
})
