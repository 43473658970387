import { FC, useContext } from 'react'
import { StoreContext } from '@components/App'
import {
  CategoryWrapper,
  CategoryTitle,
  ReferenceList,
  ReferenceItem,
  VersionList,
  VersionItem,
} from '../RightSidebar.styles'
import { observer } from 'mobx-react'

export const ConfigurationDetails: FC = observer(() => {
  const store = useContext(StoreContext)
  const { currentlyFocusedWindow, windowsActiveTabs } = store.CodeViewState

  const tabData = windowsActiveTabs[currentlyFocusedWindow]

  const openVersionPreviewTab = (version: string) => {
    const versionObject = tabData?.configurationData?.versions?.find((item) => item.clientId === version)

    store.CodeViewState.addTab({
      tab: {
        type: 'json-preview',
        title: versionObject?.clientId,
        jsonObject: JSON.stringify(versionObject, null, 4),
      },
      side: currentlyFocusedWindow,
    })
  }

  return (
    <>
      {!!tabData?.configurationData?.references?.length && (
        <CategoryWrapper>
          <CategoryTitle>References</CategoryTitle>
          <ReferenceList>
            {tabData?.configurationData?.references?.map((reference, index) => (
              <ReferenceItem key={index}>{reference}</ReferenceItem>
            ))}
          </ReferenceList>
        </CategoryWrapper>
      )}

      {!!tabData?.configurationData?.versions?.length && (
        <CategoryWrapper>
          <CategoryTitle>Versions</CategoryTitle>
          <VersionList>
            {tabData?.configurationData?.versions?.map((version, index) => (
              <VersionItem key={index} onClick={() => openVersionPreviewTab(version?.clientId)}>
                {version?.clientId}
              </VersionItem>
            ))}
          </VersionList>
        </CategoryWrapper>
      )}
    </>
  )
})
