import { styled } from 'styled-components'
import { Animatable } from '@styles/generic.styles'

export const HeaderWrapper = styled.header`
  grid-area: header;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors['activityBar.border']};
  background-color: ${({ theme }) => theme.colors['activityBar.background']};
  color: ${({ theme }) => theme.colors['sideBar.foreground']};

  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > svg {
    /* width: 84px; */
    margin-left: 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors['activityBar.border']};
    border-right: 1px solid ${({ theme }) => theme.colors['activityBar.border']};
  }

  ${Animatable}
`

export const BackButtonWrapper = styled.div`
  color: inherit;
`

export const StyledBackButton = styled.button`
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  height: 100%;
  aspect-ratio: 1;
  color: inherit;
`

export const BreadcrumbWrapper = styled.div`
  color: inherit;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const BreadcrumbItem = styled.div<{ $active?: boolean }>`
  color: inherit;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  filter: ${({ $active }) => ($active ? 'brightness(1.2)' : 'brightness(1)')};
`

export const BreadcrumbSeparator = styled.div``
