import { FC } from 'react'
import styled, { useTheme } from 'styled-components'
import { Animatable } from '@styles/generic.styles'

const SignicatLogoContainer = styled.div`
  color: ${({ theme }) => theme.signicatBranding.bgColor};
  ${Animatable}
`

export const SignicatLogo: FC = () => {
  const theme = useTheme()

  return (
    <SignicatLogoContainer>
      <svg viewBox="0 0 113 62" fill="none">
        <defs>
          <clipPath id="clip0_20_2633">
            <rect width="113" height="62" fill="currentColor" />
          </clipPath>
        </defs>
        <g clipPath="url(#clip0_20_2633)">
          <path
            d="M56.4945 2.63071V14.747C51.1258 6.07395 41.5993 0.302734 30.7226 0.302734C13.9521 0.302734 0.353516 14.054 0.353516 30.9996C0.353516 47.9451 13.9521 61.6964 30.7333 61.6964C41.5993 61.6964 51.1365 55.9252 56.5052 47.2521V59.3684H112.657V2.63071H56.4945Z"
            fill={theme.signicatBranding.primary}
          />
          <path
            d="M20.2424 37.4321C23.9287 37.4321 26.3826 35.2341 26.3826 32.2673C26.3826 29.4412 24.5074 27.9253 20.9925 27.2215C18.9029 26.8209 16.8026 26.1171 16.8026 24.3955C16.8026 23.2694 17.8313 22.3057 19.6852 22.3057C21.539 22.3057 23.1786 23.1828 23.1786 24.5362C23.1786 24.8719 23.0928 25.0993 23.0071 25.3808L25.5147 26.2579C25.7933 25.7489 25.9969 25.1318 25.9969 24.4171C25.9969 21.6236 23.4572 19.707 19.9423 19.707C16.4489 19.707 14.1664 21.9051 14.1664 24.3846C14.1664 27.5464 16.4811 29.0406 20.0817 29.6361C22.7606 30.1234 23.5965 31.0437 23.5965 32.3431C23.5965 33.7507 22.3427 34.801 20.2745 34.801C18.3564 34.801 16.5882 33.9564 16.5882 32.2348C16.5882 31.9533 16.6418 31.6393 16.7276 31.3036L14.22 30.2641C13.9628 30.7514 13.7378 31.4444 13.7378 32.159C13.7592 35.3424 16.2239 37.4321 20.2424 37.4321Z"
            fill="currentColor"
          />
          <path
            d="M28.376 22.144C28.376 23.0752 29.0725 23.779 29.9941 23.779C30.9157 23.779 31.6444 23.0752 31.6444 22.144C31.6444 21.2129 30.9264 20.4766 29.9941 20.4766C29.0832 20.4766 28.376 21.2129 28.376 22.144Z"
            fill="currentColor"
          />
          <path d="M31.2799 25.5215H28.7402V37.1722H31.2799V25.5215Z" fill="currentColor" />
          <path
            d="M39.1881 34.9535C37.3128 34.9535 35.8662 33.6325 35.8662 31.402C35.8662 28.9441 37.3236 27.4823 39.1881 27.4823C41.0849 27.4823 42.5101 29.0632 42.5101 31.2287C42.4994 33.4051 41.0527 34.9535 39.1881 34.9535ZM42.3922 25.5008V27.0817C41.6743 25.9772 40.5705 25.2734 38.7381 25.2734C35.6947 25.2734 33.2622 27.6989 33.2622 31.3911C33.2622 34.9427 35.5769 37.1732 38.4809 37.1732C40.4312 37.1732 41.7707 36.3286 42.3601 35.0834V36.3286C42.3601 38.5267 41.5242 40.075 39.3167 40.075C37.7201 40.075 36.5199 39.263 36.1555 37.6821L33.8409 38.6674C34.6767 41.2336 36.6377 42.3056 39.3167 42.3056C42.9709 42.3056 44.8998 39.7719 44.8998 36.2095V25.49H42.3922V25.5008Z"
            fill="currentColor"
          />
          <path
            d="M50.0972 30.6657C50.0972 28.6084 51.3295 27.4715 52.8298 27.4715C54.255 27.4715 55.198 28.511 55.198 30.4383V37.184H57.7377V29.5612C57.7377 26.8218 56.0339 25.2734 53.6871 25.2734C52.2618 25.2734 50.9223 25.8148 50.0543 27.2116V25.5117H47.5146V37.1624H50.0543V30.644L50.0972 30.6657Z"
            fill="currentColor"
          />
          <path
            d="M67.3285 31.2603C67.3285 28.6941 69.1503 27.4814 70.7362 27.4814C72.4401 27.4814 73.6724 28.5534 73.8974 30.275L76.2121 29.5495C75.7299 26.8426 73.5331 25.2617 70.7362 25.2617C67.575 25.2617 64.9067 27.6871 64.9067 31.2711C64.9067 34.9634 67.3393 37.4213 71.1006 37.4213C73.8974 37.4213 76.2121 35.808 76.8229 32.7437L74.5083 31.8992C74.2511 33.6208 73.2759 35.2233 70.9934 35.2233C69.1717 35.2125 67.3285 33.7074 67.3285 31.2603Z"
            fill="currentColor"
          />
          <path
            d="M83.938 35.1152C82.1163 35.1152 80.5089 33.6751 80.5089 31.3904C80.5089 29.1058 82.1056 27.579 83.938 27.579C85.6419 27.579 87.3993 29.0191 87.3993 31.3904C87.3672 33.7617 85.6204 35.1152 83.938 35.1152ZM87.2921 25.4893V27.3841C86.6277 26.0631 85.2561 25.2402 83.413 25.2402C80.2089 25.2402 77.9692 27.8064 77.9692 31.3904C77.9692 34.8337 80.2625 37.3999 83.413 37.3999C85.1704 37.3999 86.5099 36.6744 87.4315 35.2018V37.1725H89.864V25.4893H87.2921Z"
            fill="currentColor"
          />
          <path
            d="M97.9012 37.4099C100.077 37.4099 101.555 36.0564 101.78 33.7176L99.3479 33.2303C99.2621 34.5838 98.7263 35.201 97.7512 35.201C97.0332 35.201 96.2938 34.7137 96.2938 33.5876V27.719H100.677V25.4884H96.2938V21.7637H93.7648V25.4884H91.4502V27.6865H93.7648V33.4577C93.7434 35.7965 95.0829 37.4099 97.9012 37.4099Z"
            fill="currentColor"
          />
          <path
            d="M59.9985 22.144C59.9985 23.0752 60.6951 23.779 61.6166 23.779C62.5382 23.779 63.2669 23.0752 63.2669 22.144C63.2669 21.2129 62.5489 20.4766 61.6166 20.4766C60.6951 20.4766 59.9985 21.2129 59.9985 22.144Z"
            fill="currentColor"
          />
          <path d="M62.9025 25.5215H60.3628V37.1722H62.9025V25.5215Z" fill="currentColor" />
        </g>
      </svg>
    </SignicatLogoContainer>
  )
}
